import React from "react"
import styled from "styled-components"
import './content.css'

const SubDiv = styled.div`
  padding: 2%;
`
const Heading = styled.div`
  font-family: 'Playfair Display';
  color: #94040b;
  font-weight: 700;
  font-size : 30px;
  
`
export default function content({ heading, text }) {
  return (
    <div className="container">
      <SubDiv>
        <Heading >{heading}</Heading>
        <p style={{textAlign:"justify" }} dangerouslySetInnerHTML={{ __html: text }}>
        </p>
      </SubDiv>
    </div>
  )
}
