import styled from "styled-components"

export default styled.div`
color: #94040b;
font-weight: 700;
text-align : center;
border-bottom-style: solid;
border-color: "#94040b";
font-family:"Playfair Display";

`;
