import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Content from "../components/Content"
import Heading from '../components/elements/Heading'
import Layout from "../components/Layout"
import '../pages/services.css'

export const query = graphql`
  query($slug: String!) {
    contentfulBannerImages(path: {eq: "/Services"}) {
      image {
        fluid(quality:100) {
          src
        }
      }
      heading
    }
    contentfulServices(slug: { eq: $slug }) {
      slug
      heading
      paragraph{
        childContentfulRichText{
          html
        }
      }
    }
  }
`

const Services = props => {
  const ImgDiv = styled.div`
  background-image: url(${props.data.contentfulBannerImages.image.fluid.src});
  height: 300px;
  color: #ffffff;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
  return (

    <Layout>

      <ImgDiv>
        <h3
          style={{
            fontFamily: "Playfair Display",
            fontSize: 50,
            fontWeight: "bold",
            textAlign: "center",
            margin: "auto",
            paddingTop: 100,
          }}
        >
          {props.data.contentfulBannerImages.heading}
            </h3>
      </ImgDiv>
      <div className="container mt-5 mb-5" style={{textAlign:"-webkit-center"}}>
        <Heading className="headingServices"><Content heading={props.data.contentfulServices.heading} /> </Heading>
      </div>
      <Content text={props.data.contentfulServices.paragraph.childContentfulRichText.html} />

    </Layout>

  )
}

export default Services
